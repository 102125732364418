<template>
  <div class="container">
    <header>
      <van-icon
        name="arrow-left"
        size="18"
        color="rgb(162, 166, 165)"
        @click="$router.go(-1)"
      />
      <span style="margin-left: 10px">{{ showProductName() }}/USDT</span>
    </header>
    <div class="info">
      <div
        class="left down"
        :style="{
          color:
            productNowInfo[activeIndex].BasicPrice -
              productNowInfo[activeIndex].Price >
            0
              ? basicConfig.FallColour
              : basicConfig.RiseColour
        }"
      >
        <div class="price">
          {{ productNowInfo[activeIndex].Price }}
        </div>
        <div class="radio">{{ getRole() }}%</div>
      </div>
      <div class="right">
        <div>
          <span>{{ $t('contract').gao }}</span
          ><span>{{ productNowInfo[activeIndex].High }}</span>
        </div>
        <div>
          <span>{{ $t('contract').di }}</span
          ><span>{{ productNowInfo[activeIndex].Low }}</span>
        </div>
        <div>
          <span>{{ $t('contract').liang }}</span
          ><span>{{ productNowInfo[activeIndex].Amount }}</span>
        </div>
      </div>
    </div>
    <div class="h10"></div>
    <div class="data-mode-box">
      <div
        v-for="(dItem, dIndex) in dataModeList"
        :key="dIndex"
        :class="{ dataMoeActive: dataMoeActiveIndex === dIndex }"
        @click="changedataMode(dIndex)"
      >
        {{ dItem }}
      </div>
    </div>
    <div class="echar-box">
      <div class="now-data" v-if="dataMoeActiveIndex !== 0 && Kdata.length > 0">
        <div class="item">O： {{ lastPrice.Open }}</div>
        <div class="item">H： {{ lastPrice.High }}</div>
        <div class="item">L： {{ lastPrice.Low }}</div>
        <div class="item">C： {{ lastPrice.Close }}</div>
      </div>
      <div class="echars" v-show="dataMoeActiveIndex === 0">
        <EcharI :width="width" :height="height" :Ldata="Ldata" :split="split" />
      </div>
      <div class="echars" v-show="dataMoeActiveIndex !== 0">
        <EcharK
          :dayMode="dataMoeActiveIndex === 5 ? true : false"
          :width="width"
          :Kdata="Kdata"
          :height="height"
          id="b"
        />
      </div>
    </div>
    <div class="h10"></div>
    <div class="btn-box">
      <div
        class="btn"
        @click="$router.push({ name: 'Leverage', query: { mode: 0 } })"
        :style="{ background: basicConfig.RiseColour }"
      >
        {{ $t('trade').Buy }}
      </div>
      <div
        class="btn"
        @click="$router.push({ name: 'Leverage', query: { mode: 1 } })"
        :style="{ background: basicConfig.FallColour }"
      >
        {{ $t('trade').Sell }}
      </div>
    </div>
  </div>
</template>

<script>
import EcharK from '@/components/echarK'
import EcharI from '@/components/echarI'
import productApi from '@/api/product'
import { mapGetters } from 'vuex'
export default {
  components: {
    EcharK,
    EcharI
  },
  data() {
    return {
      dataMoeActiveIndex: 2,
      Kdata: [],
      activeIndex: 0,
      show: false,
      dataModeList: [],
      ProductInfo: {},
      width: '100vw',
      height: '67vw',
      lastPrice: null,
      Ldata: [],
      ProductId: null,
      split: [],
      buyModeList: [{ Id: 0, Time: 0, Win: 0, Lose: 0 }],
      productList: [],
      productNowInfo: [
        {
          BasicPrice: 0,
          Price: 0,
          High: 0,
          Low: 0,
          Amount: 0
        }
      ]
    }
  },
  computed: {
    lang() {
      this.change()
      return this.$store.getters.lang
    },
    ...mapGetters(['connection', 'basicConfig'])
  },
  mounted() {
    this.firstEnter = false
    this.change()
    this.getList()
    this.signalRStart()
  },
  destroyed() {
    if (this.connection.connectionState === 'Connected') {
      this.connection.off('product')
      this.connection.invoke('SendMsg', '')
    }
  },
  methods: {
    getRole() {
      const Role = (
        ((this.productNowInfo[this.activeIndex].Price -
          this.productNowInfo[this.activeIndex].BasicPrice) /
          this.productNowInfo[this.activeIndex].Price) *
        100
      ).toFixed(2)
      return Role > 0 ? `+${Role}` : Role
    },
    showProductName() {
      let name = ''
      this.productList.forEach(item => {
        if (item.Id === this.ProductId) {
          name = item.Name
          // if (this.lang === 'zh') {
          //   name = item.Name
          // } else if (this.lang === 'en') {
          //   name = item.EnglishName
          // } else {
          //   name = item.TraditionalName
          // }
          return
        }
      })
      return name
    },
    change() {
      let product = this.$t('contract')
      this.dataModeList = [
        product.Trend,
        '1' + product.smallMin,
        '5' + product.smallMin,
        '15' + product.smallMin,
        '1' + product.hour,
        '1' + product.dayMin
      ]
    },
    async getList() {
      this.productList = await productApi.CoinProducts()
      const ProductId = this.$route.query.Id
      this.ProductId = ProductId ? Number(ProductId) : this.productList[0].Id
      this.productNowInfo = this.productList
      this.firstSetTabProduct()
    },
    // 获得购买选项
    getbuyModeList(index) {
      this.ProductInfo = this.productList[index]
      this.buyModeList = this.productList[index].Proportion
    },
    firstSetTabProduct() {
      if (this.firstEnter) {
        return
      }
      this.productList.forEach((item, index) => {
        if (item.Id === this.ProductId) {
          this.firstEnter = true
          this.activeIndex = index
          this.getbuyModeList(index)
          this.getKData(2)
          return
        }
      })
    },
    // 选择分时图，1M,3M,5M,15M
    changedataMode(index) {
      this.dataMoeActiveIndex = index
      this.getKData(index)
    },
    async getSecondData(data) {
      let productNowInfo = data
      let productList = [...this.productList]
      productNowInfo.forEach(item => {
        productList.some(pItem => {
          if (pItem.Id === item.ProductId) {
            pItem.Price = item.Price
            pItem.Rise = item.Rise
            pItem.High = item.High
            pItem.Low = item.Low
            pItem.Amount = item.Amount
            return true
          }
        })
      })

      this.productNowInfo = productList
      if (!this.dataMoeActiveIndex) {
        data.forEach(item => {
          if (item.ProductId === this.ProductId) {
            this.Ldata.push(item.Price)
            this.split.push('')
            if (this.Ldata.length > 100) {
              this.Ldata.shift()
              this.split.shift()
            }
          }
        })
      }
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'product')
        }
      }, 100)
      connection.on('secondPrices', function(res) {
        that.getSecondData(res)
      })
    },
    async getKData(mode) {
      let res = []
      switch (mode) {
        case 1:
          res = await productApi.get1M(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 2:
          res = await productApi.get5M(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 3:
          res = await productApi.get15M(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 4:
          res = await productApi.get1Hour(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 5:
          res = await productApi.getOneDay(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        default:
          this.Ldata = []
          this.split = []
      }
      this.Kdata = res
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 50px 0;
  background: #131f30;
  height: 100vh;
  & /deep/ .van-popup--left {
    top: 0;
    height: calc(100vh - 120px) !important;
    transform: translate3d(0, 0, 0);
  }
  header {
    position: fixed;
    left: 0;
    top: 0;
    height: 50px;
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 10px;
    z-index: 99;
    background: #131f30;
    img {
      width: 20px;
      height: 15px;
      margin: 0 10px;
    }
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .info {
    background: #131f30;
    display: flex;
    align-items: center;
    height: 90px;
    padding: 10px 20px;
    font-size: 14px;
    .left {
      flex: 1;
      // margin-right: 70px;
      text-align: center;
      .price {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 24px;
        line-height: 1.5;
      }
    }
    .right {
      flex: 1;
      div {
        display: flex;
        line-height: 1.6;
        justify-content: space-between;
        color: #9fbedc;
        span:nth-of-type(2) {
          color: #fff;
        }
      }
    }
  }
  .h10 {
    height: 5px;
    width: 100vw;
    background: #000;
  }
  .echar-box {
    position: relative;
    .now-data {
      position: absolute;
      left: 20px;
      top: 13px;
      color: #4e5b85;
      line-height: 16px;
      z-index: 10;
    }
  }
  .data-mode-box {
    display: flex;
    background: #131f30;
    height: 28px;
    margin-top: 5px;
    div {
      flex: 1;
      color: #999999;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .dataMoeActive {
      color: #8e1473;
      border-bottom: 1px solid #8e1473;
    }
  }
  .btn-box {
    display: flex;
    padding: 30px 10px;
    justify-content: space-around;
    .btn {
      width: 130px;
      height: 37px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
